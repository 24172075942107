import { apiUrl, axios } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export const getAllFAQ = async (filter = {}, pageNumber = 1, pageSize = PAGE_SIZE) => {
    try {
        const params = {
            'page-size': pageSize,
            page: pageNumber,
            filter: JSON.stringify(filter),
            sort: 'sort',
            include: 'files'
        };

        const result = await axios.get(`${apiUrl}/help`, { params });
        return {
            allQuestions: [...result.data.data],
            included: result.data.included
        }
    } catch (error) {
        generateErrors(error)
    }
};

export const getFAQById = async id => {
    try {
        const result = await axios.get(`${apiUrl}/help/${id}`)
        return {
            data: result.data.data,
            included: [],
        }
    } catch (error) {
        generateErrors(error)
    }

};


export const registerNewFAQ = async data => {
    try {
        const response = await axios.post(`${apiUrl}/help`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
};

export const patchFAQ = async (id, data) => {
    try {
        const response = await axios.patch(`${apiUrl}/help/${id}?include=files`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
};

export const deleteFAQ = async id => {
    try {
        const response = await axios.delete(`${apiUrl}/help/${id}`);

        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export const deleteFAQfile = async fileId => {
    try {
        const response = await axios.delete(`${apiUrl}/file/${fileId}`);

        return response;
    } catch (error) {
        generateErrors(error);
    }
}
