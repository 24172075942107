<template>
    <div class="p-col-12 p-grid p-fluid p-mx-0 p-px-0">
        <!-- <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading" style="z-index: 1">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div> -->
        <div class="p-col-12 p-d-flex p-jc-md-between p-flex-wrap p-pt-0 p-px-0">
            <div class="p-col-7 p-p-0 p-d-flex p-ai-center">
                <h5 class="p-d-inline p-mb-0">Справка</h5>
            </div>
            <!-- <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-mt-0"></div> -->
            <div class="layout-notification-wrapper" style="width: 100%;">
                <Message v-for="message of notifications" severity="warn" :key="message.id">
                    <div v-html="message.attributes.text" />
                </Message>
            </div>
            <div class="p-col-12 p-p-0 p-d-flex p-ai-center">
                <div class="p-inputgroup p-p-0 p-d-flex mar12 p-jc-end">
                    <span class="p-float-label">
                        <InputText type="text" v-model="searchValue" placeholder="Поиск" />
                    </span>
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-search"></i>
                    </span>
                </div>
                <div v-if='canEdit' class="p-p-0 p-new-btn">
                    <Button @click="addNewFAQ" class="p-button"> Добавить </Button>
                </div>
            </div>
        </div>
        <Accordion class="p-col-12 p-px-0 p-pt-0">
            <AccordionTab v-for="question in filterFAQ" :key="question.id">
                <template #header>
                    <div class="p-col-12 p-d-flex p-ai-center p-jc-between p-py-0 p-pl-0">
                        <span class="p-ml-2 p-text-normal customLength">{{ question.attributes.name }}</span>
                        <!-- <div class="p-col-1 p-d-flex p-ai-center p-jc-end p-py-0 p-pl-0"> -->
                        <template v-if='accessibleItems(listMenuItems).length'>
                            <div class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, question)">
                                <span class="pi pi-ellipsis-h"></span>
                            </div>
                            <Menu class="redLastListElement" :ref="`listMenu${question.id}`" :model="accessibleItems(listMenuItems)" :popup="true" :baseZIndex="10" />
                        </template>
                        <!-- <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click.stop="$router.push(`/FAQ/${question.id}`)" />
                            <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click.stop="removeFAQ(question.id)" /> -->
                        <!-- </div> -->
                    </div>
                </template>
                <p class="p-text-normal">{{ question.attributes.description }}</p>
                <p class="p-d-flex p-flex-column p-jc-center fileItemContainer">
                    <span v-for="questionFile in question.files" :key="questionFile.id" class="fileItem" @click="downloadFile(questionFile.attributes.name, questionFile.attributes.downloadUri)">
                        <span class="pi pi-file"></span>
                        {{ questionFile.attributes.name }}
                    </span>
                </p>
            </AccordionTab>
        </Accordion>
        <div class="p-col-12 p-d-flex p-jc-md-between p-flex-wrap p-pt-0 p-px-0" v-if="filterFAQ.length == 0">По таким критериям элементов справки не найдено</div>
        <Dialog :visible.sync="createChangeFAQDialog" header="" position="center" :modal="true" :closeOnEscape="true" :closable="true">
            <template #header>
                <h3 class="p-mb-0">{{ FAQTitle }}</h3>
            </template>
            <div class="p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0 errorPos">
                <label class="p-text-normal" for="system">Заголовок*</label>
                <InputText id="system" v-model="v$.FAQName.$model" type="text" placeholder="" :class="v$.FAQName.$invalid && submitted ? 'p-error' : ''"> </InputText>
                <small v-show="submitted" v-for="error in showErrors(v$.FAQName)" :key="error.$uid" class="p-error errorPosition">{{ error.$message }}</small>
            </div>

            <div class="p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0 errorPos">
                <label class="p-text-normal" for="text">Текст*</label>
                <Textarea id="text" v-model="v$.FAQText.$model" placeholder="Введите текст сообщения" :autoResize="true" rows="11" cols="30" :class="v$.FAQText.$invalid && submitted ? 'p-error' : ''" />
                <small v-show="submitted" v-for="error in showErrors(v$.FAQText)" :key="error.$uid" class="p-error errorPosition">{{ error.$message }}</small>
            </div>
            <div class="p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0">
                <div class="p-col-12 p-px-0 p-py-2 p-d-flex p-jc-start p-ai-center uploadItem" v-for="uploadItem in uploadfiles" :key="uploadItem.id">
                    <Button :label="uploadItem.file ? 'Изменить файл ' : 'Загрузить файл'" icon="pi pi-upload" iconPos="left" class="uploadwidth p-mr-2" @click="choiceFile(uploadItem.id)" />
                    <span class="fileNameLength">{{ uploadItem.file && uploadItem.file.name }}</span>
                    <input :ref="`uploadItem${uploadItem.id}`" type="file" @change="onFileSelect($event, uploadItem.id)" class="uploadInput" />
                    <Button icon="pi pi-times-circle" class="p-button-raised p-button-danger p-button-text" @click="remoweFile(uploadItem.id)" v-if="uploadItem.file" />
                </div>
            </div>

            <div class="p-col-12 p-lg-12 p-mb-lg-0 p-px-0 p-py-0">
                <label class="p-text-normal" for="sort">Сортировка</label>
                <InputNumber id="sort" v-model="noteSort" type="text" placeholder="100" :min="0" :max="100"> </InputNumber>
            </div>
            <div class="p-col-12 p-lg-12 p-field-checkbox p-px-0 p-py-0 checkBox">
                <Checkbox id="checkOption1" name="option" :binary="true" v-model="v$.active.$model" />
                <label for="checkOption1">Активный</label>
            </div>

            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button v-if="FAQTitle == 'Редактировать справку'" class="p-button" @click="patchCurrentFAQ">
                        <span class="p-button-label">Сохранить</span>
                    </Button>
                    <Button v-else class="p-button" @click="submitFAQ">
                        <span class="p-button-label">Сохранить</span>
                    </Button>
                    <Button class="p-button p-button-outlined p-button-secondary" type="button" @click="clearFAQDialog">
                        <span class="p-button-label">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { getAllFAQ, registerNewFAQ, patchFAQ, deleteFAQ, deleteFAQfile } from '@/api/FAQ';
import { getNotificationByStartPage } from '@/api/notifications';
import { uploadFile } from '@/api/common';
import { downloadURI } from '@/main/utils/common';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { requestToastHandler } from '@/main/mixins';
import { v4 as uuidv4 } from 'uuid';
import { USER_PERMISSIONS_MAP } from '@/constants/common';
import { mapGetters } from 'vuex';

const {
    helpWrite
} = USER_PERMISSIONS_MAP

export default {
    name: 'FAQList',
    // props: {
    //     loading: {
    //         type: Boolean,
    //         require: false,
    //     },
    // },
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        this.listMenuItems = [
            {
                label: 'Редактировать',
                icon: 'pi pi-pencil',
                command: () => {
                    this.startEdit(this.editFAQId);
                },
                permissionAccess: [helpWrite]
                // disabled: this.checkStatus(this.editFAQId),
            },

            {
                label: 'Удалить',
                icon: 'pi pi-trash',
                command: () => {
                    // this.removeFAQ(this.editFAQId);
                    this.$root.$emit('showAcceptDeleteDialog', {
                        acceptAction: () => this.removeFAQ(this.editFAQId)
                    })
                },
                class: 'remove-row',
                permissionAccess: [helpWrite]
            },
        ];
        return {
            notifications: null,
            loading: false,
            allFAQ: [],
            filterFAQ: [],
            createChangeFAQDialog: false,
            searchValue: null,
            editFAQId: null,
            FAQTitle: '',
            FAQName: '',
            FAQText: '',
            noteSort: 100,
            active: true,
            uploadFile: null,
            submitted: false,
            uploadfiles: [
                {
                    label: 'Загрузить файл',
                    id: uuidv4(),
                    file: null,
                },
            ],
        };
    },
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символов` },
        });
        return {
            FAQName: { ...standart(), maxLength: { ...maxLength(200), $message: 'Длина заголовка не должна превышать 200 символов' } },
            FAQText: standart(10),
            active: {},
        };
    },
    methods: {
        async checkFileForUpload() {
            try {
                let fileArray = [];
                let resultArray = [];
                this.uploadfiles.map(async (uploadfile) => {
                    if (uploadfile.file) {
                        if (uploadfile.file.uri) resultArray.push(uploadfile);
                        else {
                            const fD = new FormData();
                            fD.append('file', uploadfile.file);
                            fileArray.push(uploadFile(fD).then(({ data }) => resultArray.push(data[0])));
                        }
                    }
                });
                await Promise.all(fileArray);
                return resultArray;
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        remoweFile(id) {
            const curentUploadItem = this.uploadfiles.find((i) => i.id == id);
            curentUploadItem.file = null;
            this.uploadfiles.splice(this.uploadfiles.length - 1, 1);
        },
        choiceFile(itemIndex) {
            //  this.$refs[`uploadItem${itemIndex}`][0]
            this.$refs[`uploadItem${itemIndex}`][0].click();
        },
        onFileSelect(event, index) {
            const curentUploadItem = this.uploadfiles.find((i) => i.id == index);

            if (!curentUploadItem.file) {
                this.uploadfiles.push({
                    label: 'Загрузить файл',
                    id: uuidv4(),
                    file: null,
                });
            }
            curentUploadItem.file = event.target.files[0];
        },
        startEdit(faqId) {
            const FAQData = this.allFAQ.find((faq) => faq.id == faqId);

            this.FAQFiles = FAQData.files;
            this.FAQName = FAQData.attributes.name;
            this.FAQText = FAQData.attributes.description;
            this.noteSort = FAQData.attributes.sort;
            this.active = FAQData.attributes.active;
            this.editFAQId = FAQData.id;
            this.FAQTitle = 'Редактировать справку';
            FAQData.files &&
                FAQData.files.reverse().map((file) => {
                    this.uploadfiles.unshift({
                        label: 'Загрузить файл',
                        id: file.id,
                        file: file.attributes,
                    });
                });

            this.createChangeFAQDialog = true;
        },
        addNewFAQ() {
            this.clearFAQDialog();
            this.FAQTitle = 'Добавить справку ';
            this.createChangeFAQDialog = true;
        },
        async submitFAQ() {
            this.submitted = true;
            if (this.formIsValid) {
                try {
                    this.$emit('loadingChange', true);
                    this.createChangeFAQDialog = false;
                    const fileArray = await this.checkFileForUpload();
                    const dataToServer = this.generateServerData(fileArray);
                    // dataToServer;
                    // registerNewFAQ;
                    const result = await registerNewFAQ(dataToServer);
                    if (result) this.clearFAQDialog(), this.getFAQ();
                } catch (error) {
                    this.$requestError(error.message);
                    return;
                }

                this.v$.$anyDirty = false;
            }
            this.$emit('loadingChange', false);
        },
        async patchCurrentFAQ() {
            this.submitted = true;
            if (this.formIsValid) {
                try {
                    this.$emit('loadingChange', true);
                    this.createChangeFAQDialog = false;
                    const fileArray = await this.checkFileForUpload();
                    const dataToServer = this.generateServerData(fileArray);
                    // dataToServer;
                    // registerNewFAQ;
                    const result = await patchFAQ(this.editFAQId, dataToServer);
                    const {
                        data: {
                            data: {
                                relationships: {
                                    files: { data },
                                },
                            },
                        },
                    } = result;
                    console.log('Перед удалением', data);
                    this.checkFileFordDelete(data);
                    if (result) this.clearFAQDialog(), this.getFAQ();
                } catch (error) {
                    this.$requestError(error.message);
                    return;
                }

                this.v$.$anyDirty = false;
            }
            this.$emit('loadingChange', false);
        },
        abortChanges() {
            this.v$.$anyDirty = false;

            this.$router.push(this.leaveRoute);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        clearFAQDialog() {
            this.createChangeFAQDialog = false;
            this.uploadFile = null;
            (this.uploadfiles = [
                {
                    label: 'Загрузить файл',
                    id: uuidv4(),
                    file: null,
                },
            ]),
                (this.FAQFiles = null);
            this.FAQName = null;
            this.FAQText = null;
            this.noteSort = null;
            this.active = null;
            this.editFAQId = null;
            this.submitted = false;
        },
        filteredFAQ(filterInput) {
            if (!filterInput) return (this.filterFAQ = this.allFAQ);
            return (this.filterFAQ = this.allFAQ.filter((FAQ) => FAQ.attributes.name.indexOf(filterInput) >= 0));
        },
        async getFAQ() {
            try {
                this.$emit('loadingChange', true);
                const { data } = await getNotificationByStartPage();
                if (data) {
                    this.notifications = data.data.map(item => {
                        item.attributes.text = item.attributes.text.replace(/\n/g, '<br/>');
                        return item;
                    });
                }

                const { allQuestions, included } = await getAllFAQ(!this.canEdit ? { active: true } : {});

                this.allFAQ = allQuestions
                    .map((question) => {
                        if (question.relationships.files && question.relationships.files.data.length) {
                            question['files'] = question.relationships.files.data.map((file) => {
                                const curFile = included.find((f) => f.id == file.id);
                                return curFile;
                            });
                        }

                        return question;
                    });

                this.filterFAQ = this.allFAQ;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        async removeFAQ(id) {
            try {
                const FAQData = this.allFAQ.find((faq) => faq.id == id);
                this.FAQFiles = FAQData.files;
                this.FAQFiles;
                const response = await deleteFAQ(id);
                if (response.status == 204) {
                    this.checkFileFordDelete([]);
                    this.getFAQ();
                }
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        toggleRowMenu(event, slotProps) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            const { id } = slotProps;
            Object.keys(this.$refs).map((key) => {
                this.$refs[`${key}`][0] && this.$refs[`${key}`][0].hide(event);
            });
            this.editFAQId = id;
            // Open new popup
            this.$refs[`listMenu${id}`][0].toggle(event);
        },
        downloadFile(fileName, fileUri) {
            const ext = fileUri?.split('.').pop();

            downloadURI(fileName, ext, fileUri);
        },
        generateServerData(fileArray) {
            let relationships = null;
            if (fileArray.length) {
                relationships = {
                    relationships: {
                        files: {
                            data: [
                                ...fileArray.map(({ id }) => ({
                                    type: 'file',
                                    id: id,
                                })),
                            ],
                        },
                    },
                };
            } else {
                relationships = {
                    relationships: { files: { data: [] } }
                };
            }
            const dataToServer = {
                data: {
                    type: 'help',
                    id: this.editFAQId ? this.editFAQId : null,
                    attributes: {
                        name: this.FAQName,
                        description: this.FAQText,
                        sort: this.noteSort || 0,
                        active: this.active,
                    },
                    ...relationships,
                },
            };

            return dataToServer;
        },
        checkFileFordDelete(fileArray = []) {
            if (!this.FAQFiles) return;
            this.FAQFiles.map((file) => {
                if (!fileArray.find((checkedFile) => checkedFile.id == file.id)) {
                    deleteFAQfile(file.id);
                    // deleteFAQfile;
                }
            });
        },
    },
    computed: {
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        formIsValid() {
            return !this.v$.$invalid;
        },
        canEdit() {
            return [helpWrite].some(p => this.userPermissionsObject[p])
        }
    },

    watch: {
        searchValue() {
            this.filteredFAQ(this.searchValue);
        },
    },
    beforeRouteLeave(to, from, next) {
        this.leaveRoute = to.fullPath;
        if (this.v$.$anyDirty) {
            // this.unsavedChangesShow = true;
            // this.$root.$emit('show-leave-dialog', {
            //     acceptCb: async () => await this.patchCurrentFAQ(),
            //     beforeClose: next
            // })
            // return;
        }
        next();
    },
    async mounted() {
        await this.getFAQ();
        this.$emit('loadingChange');
    },
};
</script>

<style lang="scss" scoped>
.fileItem {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #388e3c;
    margin-bottom: 8px;
    cursor: pointer;
}
.fileItemContainer {
    .fileItem:first-child {
        margin-top: 8px;
    }
    .fileItem:last-child {
        margin-bottom: 0;
    }
}
::v-deep .uploadItem {
    .p-button-raised,
    .p-button.p-button-danger:enabled:focus {
        box-shadow: none;
    }
    .fileNameLength {
        max-width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.uploadInput {
    display: none;
}
.uploadwidth {
    width: 164px;
}
::v-deep .p-fileupload {
    margin-bottom: 16px;
}
.p-component-overlay {
    position: fixed !important;
}
::v-deep .p-dialog {
    width: 480px !important;
    top: calc(50% - 320px);
    left: calc(50% - 240px);
    .p-dialog-header {
        padding: 24px;
        .p-dialog-header-icon:focus {
            box-shadow: none;
        }
        h3 {
            font-size: 18px;
            color: #272727;
        }
    }
    .p-dialog-content {
        padding: 0 24px;

        label {
            font-size: 13px;
            line-height: 16px;
            color: #272727;
            margin-bottom: 4px;
            display: block;
        }
        .p-field {
            margin-bottom: 0;
        }
        .checkBox {
            margin-bottom: 16px;
            label {
                margin-bottom: 0;
            }
        }
        .errorPos {
            position: relative;
        }
        .errorPosition {
            position: absolute;
            left: 0;
            bottom: 4px;
        }
        .message {
            margin-bottom: 16px;
        }
        .p-fileupload-content {
            padding: 0;
            min-height: 40px;
            padding: 10px;
            .p-fileupload-row {
                div {
                    padding: 7px;
                }
                div:first-child {
                    width: 65% !important;
                }
            }
        }
        .p-fileupload-buttonbar {
            padding: 10px;
            span:nth-child(1) {
                margin-right: 12px;
            }
            button:nth-child(2) {
                display: none !important;
            }
            .p-button {
                box-shadow: none;
            }
        }
    }

    .p-inputtext,
    .p-dropdown {
        padding: 8px 12px;
        font-size: 13px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
    }
    .p-dropdown {
        .p-inputtext {
            margin-bottom: 0;
        }
    }
    .p-dialog-footer {
        padding: 0 24px 24px 24px;
        button {
            margin: 0 12px 0 0;
        }
    }
    .p-pr8 {
        padding-right: 8px;
    }
    .p-pl8 {
        padding-left: 8px;
    }
}
::v-deep .redLastListElement {
    width: 240px;
    position: fixed !important;
    display: block !important;
    z-index: 1042;
    transform: translateX(-10%);
    .remove-row {
        span {
            color: red !important;
        }
    }
    .p-menuitem {
        height: 36px;
        display: flex;
        align-items: center;
        width: 100%;
        .p-menuitem-link {
            height: 100%;
        }

        .pi {
            font-size: 1rem !important;
        }
        &:hover {
            background: #eaeaea;
        }
        &-link {
            white-space: nowrap;
            width: 100%;
            padding: 0 1rem;
            .empty {
                width: 14px;
                height: 14px;
                margin-right: 7px;
            }
            &:hover {
                background: unset;
            }
            &:focus {
                box-shadow: unset;
            }
        }
    }
}
.p-inputtext:enabled:hover {
    border-color: #eaeaea;
}
.p-inputgroup .p-float-label input {
    border-right: none;
}
.p-inputgroup-addon {
    background: inherit;
    border-left: none;
}
.p-new-btn {
    height: 100%;
    .p-button {
        height: 100%;
    }
}
::v-deep .mar12 {
    margin-right: 12px;
    .p-float-label {
        width: 470px;
    }
}
::v-deep .p-accordion-header {
    height: 44px;

    .p-accordion-header-link {
        padding: 12px 50px 10px 41px;
        height: 100%;
        background: #eaeaea;
        border: 1px solid #e0e0e0;
        .p-accordion-toggle-icon {
            position: absolute;
            top: 17px;
            left: 20px;
        }
        .pi {
            font-size: 8px;
        }
        .p-panel-header-icon {
            position: absolute;
            top: 14px;
            right: 15px;
            .pi {
                font-size: 14px;
            }
        }
    }
}
::v-deep .p-accordion-tab-active {
    .p-highlight {
        .p-accordion-header-link {
            background: #eaeaea !important;
            border-color: #e0e0e0 !important;
        }
    }
}
::v-deep .p-accordion-content {
    padding: 16px 12px;
    p {
        max-width: 700px;
        word-wrap: break-word;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 0px;
    }
}
// ::v-deep .p-menu-overlay {
//     position: static;
// }
.customLength {
    max-width: 100%;
    white-space: pre-wrap;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    margin-left: 0 !important;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
}
</style>
